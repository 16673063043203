.features .icon-box {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #f6f6f6;
    transition: ease-in-out 0.3s;
  }
  
  .features .icon-box .icon {
    width: 40%;
    padding-right: 20px;
    line-height: 1;
  }
  
  .features .icon-box h6 {
    font-weight: 500;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 16px;
  }
  
  .features .icon-box h6 a {
    color: #222222;
    transition: ease-in-out 0.3s;
  }
  
  .features .icon-box h6 a:hover {
    color: #3498db;
  }
  
  .features .icon-box:hover {
    background: #eef7fc;
  }