@keyframes scrolled {
  0% {
    top: -70px;
  }
  100% {
    top: 0;
  }
}
.headerr {
  
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  @media (min-width: 768px) {
    position: absolute;
  }
  .navbar-brand {
   
    width: 170px;
    @media (max-width: 768px) {
     width: 140px;
    }
    i {
      color: #248eff;
      font-size: 0.5rem;
    }
  }
  .navbar {
    background-color: transparen;
    
    &.scrolled {
      @media (min-width: 220px) {
        position: fixed;
        background-color: #f8f9fa!important;
        box-shadow: 0px 2px 6px rgba(43, 73, 111, 0.2);
        left: 0;
        right: 0;
        animation: scrolled 0.3s ease forwards;
      }
    }
  }
  .nav-link {
    text-transform: uppercase;
    font-weight: 700;
    color: #242424 !important;
    font-size: 0.85rem;
    &:hover {
      color: darken(#0070e7, 6%) !important;
    }
    &.active {
      color: lighten(#004c9c, 5%) !important;
    }
    @media (min-width: 768px) {
      padding: 1rem 1rem;
    }
  }


  .nav-icon {
    width: 30px;
    height: 25px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #4e4e4e;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
      &:nth-child(1) {
        top: 0px;
      }
      &:nth-child(2) {
        top: 9px;
      }
      &:nth-child(3) {
        top: 18px;
      }
    }
    &.open {
      span {
        &:nth-child(1) {
          top: 9px;
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: rotate(135deg);
        }
        &:nth-child(2) {
          opacity: 0;
          left: -60px;
        }
        &:nth-child(3) {
          top: 9px;
          -webkit-transform: rotate(-135deg);
          -moz-transform: rotate(-135deg);
          -o-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }
      }
    }
  }
}
