.termsConditions h3 {
    text-align: center;
    font-weight: 700;
    padding: 80px 0 20px 0;
}
.termsConditions {
    position: relative;
    top: 30px;
    width: auto;
    font-family: emoji;
}
@media (max-width: 1200px) {
    .termsConditions {
        //max-width: 940px;
        margin: 0 250px 100px 250px;
    }
}
@media (min-width: 1200px) {
    .termsConditions {
        //max-width: 940px;
        margin: 0 250px 100px 250px;
    }
}
@media (max-width: 900px)  {
    .termsConditions {
        //max-width: 940px;
        margin: 0 150px 100px 150px;
    }
}
@media (max-width: 768px) {
    .termsConditions {
        //max-width: 940px;
        margin: 0 100px 100px 100px;
    }
}
@media (max-width: 517px) {
    .termsConditions {
        //max-width: 940px;
        margin: 0 50px 100px 50px;
    }
}

@media (max-width: 200px) {
    .termsConditions {
        //max-width: 940px;
        margin: 0 0px 50px 0px;
    }
}
