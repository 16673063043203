#contact {
    padding: 60px 0;
  }
  
  #contact .contact-info {
    margin-bottom: 20px;
    text-align: center;
  }
  
  #contact .contact-info h3 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
    text-transform: uppercase;
    color: #555;
  }
  
  #contact .contact-info a {
    color: #444;
  }
  
  #contact .contact-info a:hover {
    color: #3498db;
  }
  #contact .contact-phone {
   color: #3498db;
   
  }
  
  
  @media (min-width: 768px) {
    #contact .contact-addresss, #contact .contact-phone, #contact .contact-email{
      padding: 20px 0;
    }
  
  }
  #contact .icon{
    font-size: 48px;
    display: inline-block;
    margin-bottom: 20px;
    color: #3498db;
      
  }
  
  @media (min-width: 768px) {
    #contact .contact-phone{
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }
   
  }
  
  #contact .php-email-form {
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 30px;
  }
  
  
  
  #contact .php-email-form .form-group {
    margin-bottom: 20px;
  }
  
  #contact .php-email-form input, #contact .php-email-form textarea {
    padding: 10px 14px;
    border-radius: 0;
    box-shadow: none;
    font-size: 15px;
  }
  
  #contact .php-email-form input::focus, #contact .php-email-form textarea::focus {
    background-color: #3498db;
  }
  
  #contact .php-email-form button[type="submit"] {
    background: #3498db;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    cursor: pointer;
  }
  
  #contact .php-email-form button[type="submit"]:hover {
    background:  #0c75bb;
    border-color: #3498db;
  }
  
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  