#hero {
  width: 100%;
  height: 85vh;
  margin-top: 70px;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #333;
  
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #4D8AFD;
  border: 2px solid #4D8AFD;
}

#hero .btn-get-started:hover {
  background:  #4D8AFD;
  color: #fff;
}

#hero .animated {

  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 1024px) {
  #hero {
    background-attachment: fixed;

    
  }
}
#hero .hero-img img {
  width: 100%;
}

@media (max-width: 991px) {
  #hero {
    height: 130vh;
  }
  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }
  #hero .hero-img {
    text-align: center;
    width: 70%;
    
  }
  
}
@media (max-width: 768px) {
  #hero {
    margin-top: 20px;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #hero .hero-img img {
    width: 100%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 90%;
  }
  #hero {
    height: 100vh;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@media (max-width: 700px) {
  #hero {
  height: calc(180vh );
  position: relative;
  margin-top: 56px;
  } 
}
@media (max-width: 500px) {
  #hero {
  height: calc(80vh );
  position: relative;
  margin-top: 56px;
  } 
}