.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  background: #b2cecf;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
  height: 330px;
  position: relative;
  overflow: hidden;
}
@media (min-width: 1024px){
  .team .member .member-img {
  height: 270px;
  position: relative;
  overflow: hidden;
}
}
@media (max-width: 425px){
  .team .member .member-img {
  height: 350px;
  position: relative;
  overflow: hidden;
}
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #222222;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #8798a3;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
}